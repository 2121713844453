<template>
  <Layout>
    <PageHeader :title="title" :items="state.items"/>
    <div>
      <form>
        <div class="form-group">
          <div class="row">
            <div class="col-sm">
              <div v-for="(key, index) in Object.keys(state.filesError)" :key="`error_${index}`">
                <div v-if="state.filesError[key].length > 0" class="text-danger">
                  {{ state.filesError[key].join(', ') }}: {{$t(`validateFile.${key}`)}}
                </div>
              </div>
            </div>
            <div class="col-sm-auto">
              <div class="d-flex justify-content-end">
                <label for="my-file" class="form-control-file btn btn-primary">
                  {{ $t('t-select-image') }}
                  <i class="bi bi-0-circle-fill"></i>
                  <img src="@/assets/images/cloud-plus-fill.svg" alt="" class="rounded-circle" style="width: 1.3rem"/>

                  <input type="file" accept="image/*" hidden multiple="multiple" @change="previewMultiImage"
                         id="my-file">
                </label>
              </div>
            </div>
          </div>


          <div class="border p-2 mt-3" style="min-height: 100vh">
            <div class="row" v-if="preview_list.length">
              <div v-for="(item, index) in preview_list" :key="index" class="col-md-2 col-6 mb-2">
                <div style="height: 200px; background-color: #cccccc;border-radius: 4px;">
                  <img :src="item.image" class="img-thumbnail w-100 h-100" :alt="item.name"/>
                </div>

                <div class="d-flex justify-content-between align-items-center">
                  <p class="mb-0 text-format my-2">{{ item.name }}</p>
                  <div class="py-2 cursor-pointer pl-2" style="padding-left: 0.5rem" @click="savePathFile(item.name, index)">
                    <img v-if="item.isCopy" src="@/assets/images/duplicate.png" alt="" class="rounded-circle"
                         style="width: 1.3rem"/>
                    <i v-else class="bx bx-check-circle fs-20 text-success"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <Observer @intersect="handleScroll"/>
  </Layout>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import i18n from "@/i18n";
import {clientOSS, generateImageOss} from "@/helpers/axios/formDataRequest";
import {validateExtension} from "@/helpers/axios/formDataRequest";
import Observer from '@/components/common/Observer';
import { copyText } from 'vue3-clipboard';
import {detectExtensionFile} from "@/state/helpers";

export default {
  name: "UploadImage",
  components: {
    Layout,
    PageHeader,
    Observer
  },
  setup() {
    const client = clientOSS;
    const numberShow = ref(20);
    const paginate = reactive({
      pageSize: 20,
      nextMarker: ''
    });

    const uploadFiles = async (file) => {
      try {
        const result = await client.put(`setting-upload/${file.name}`, file);

        return result.url
      } catch (e) {
        console.log(e, 'e=====')
      }
    }

    const title = ref(i18n.global.t('t-setting_'))
    const preview_list = ref([]);
    const state = reactive({
      filesError: {
        extensionNotSupport: [],
        sizeLage: [],
        sizeZero: []
      },
      items: [
        {
          text: i18n.global.t('t-setting_'),
          active: true
        },
        {
          text: i18n.global.t('t-media-upload'),
          active: true,
        },
      ]
    })

    const previewMultiImage = async (event) => {
      const input = event.target;
      let count = input.files.length;

      state.filesError = {
        extensionNotSupport: [],
        sizeLage: [],
        sizeZero: []
      }

      let index = 0;
      if (input.files) {
        while (count--) {
          let reader = new FileReader();
          let urlAfterUpload = "";
          const validateFile = validateExtension(input.files[index]);

          if (validateFile.validate) {
            urlAfterUpload = await uploadFiles(input.files[index]);

            const info = input.files[index];
            reader.onload = (e) => {
              preview_list.value.unshift({
                image: e.target.result,
                url: urlAfterUpload,
                name: info.name,
                isCopy: true
              });
            }
            reader.readAsDataURL(input.files[index]);
          } else {
            state.filesError[validateFile.type].push(validateFile.fileName)
          }

          index++;
        }
        //await initArray();
      }
    }

    const savePathFile = (pathFile, index) => {
      copyText(`${pathFile}`, undefined, (error) => {
        if (error) {
          alert('Can not copy')
        } else {
          preview_list.value[index].isCopy = false;
          setTimeout(() => {
            preview_list.value[index].isCopy = true;
          }, 2000)
        }
      })
      /*return new Promise((resolve, reject) => {
        if (!(typeof navigator !== 'undefined' && typeof navigator.clipboard != 'undefined' && typeof navigator.permissions !== 'undefined')) {

          window.navigator.clipboard.writeText(pathFile).then(resolve, reject).catch(reject);
        } else {
          const textarea = document.createElement("textarea");
          textarea.textContent = pathFile;
          textarea.style.display = 'none';
          document.body.appendChild(textarea);
          textarea.focus();
          textarea.select();
          try {
            preview_list.value[index].isCopy = false;
            setTimeout(() => {
              preview_list.value[index].isCopy = true;
            }, 2000)
            document.execCommand("copy");
            document.body.removeChild(textarea);
            resolve();
          } catch (e) {
            document.body.removeChild(textarea);
            reject(e);
          }
        }
      })*/
    }
    const handleScroll = async () => {
      if (paginate.nextMarker !== null) {
        const test = await getListFromAliOSS(paginate.pageSize, paginate.nextMarker);
        paginate.nextMarker = test.nextMarker;
        await pushItemToPreview(test.objects);

        if (preview_list.value.length > numberShow.value) {
          numberShow.value += 20;
        }
      }
    }

    const getListFromAliOSS = async (pageSize, marker) => {
      return await clientOSS.list(
          {
            'max-keys': pageSize,
            'marker': marker,
            'prefix': 'setting-upload/'
          }
      );
    }

    const convertImage = (name) => {
      return generateImageOss(name);
    }

    const pushItemToPreview = (array) => {
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        const type = detectExtensionFile(item);
        if (type.type === 'image') {
          let temp = convertImage(item.name);
          preview_list.value.push({
            image: temp,
            url: item.url,
            isCopy: true,
            name: item.name
          })
        }

      }
    }

    const initArray = async () => {
      const result = await getListFromAliOSS(20, '');
      paginate.nextMarker = result.nextMarker;
      await pushItemToPreview(result.objects);
    }

    onMounted(async () => {
      await initArray();
    })

    return {
      preview_list,
      title,
      savePathFile,
      previewMultiImage,
      handleScroll,
      numberShow,
      paginate,
      state
    }
  }
}
</script>

<style scoped>
.text-format {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
</style>
