
<!-- eslint-disable no-undef -->
<script setup>
import { onUnmounted, onMounted, ref } from 'vue';

const props = defineProps({
    options: {
        type: Object,
        default: () => { },
    },
})

const emit = defineEmits(['intersect'])
const ele = ref(null);
const observer = ref(null);

onMounted(() => {
    const options = props.options || {threshold: 1, rootMargin: '0px'};
    observer.value = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting) {
            emit("intersect");
        }
    }, options)
    observer.value.observe(ele.value);
})

onUnmounted(() => {
    observer.value.disconnect();
})
</script>

<template>
    <div ref="ele" class="observer" />
</template>